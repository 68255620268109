import React from 'react';
import {BlogDetailsSection} from './blogdetailspage.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import BlogDetails from "../BlogDetails";
import BlogSidebar from "../BlogSidebar";

const BlogDetailsPage  = () => {
    return (
        <BlogDetailsSection>
            <Container>
                <Row>
                    <Col lg={8}>
                        <BlogDetails/>
                    </Col>
                    <Col lg={4}>
                        <BlogSidebar />
                    </Col>
                </Row>
            </Container>
        </BlogDetailsSection>
    );
}
export default BlogDetailsPage;