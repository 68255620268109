import styled from 'styled-components';
import {device} from '../../../components/device';

export const BlogDetailsSection = styled.section`
    padding:100px 0px;
    border-bottom:1px solid #ccc;

    @media ${device.tablet} {
        padding:80px 10px;
    }
`;