import styled from 'styled-components';
import {Commonbtn,Commonpara,Commonh3,Commonh4,AnchorLink} from '../../../components/common.style';
import {device} from '../../../components/device';

export const BlogDetailsWrapper = styled.div`
    @media ${device.laptop} {
        margin-bottom:30px;
    }
`;
export const BlogContent = styled.div`
    margin-bottom:30px;
    background:#fafafa;
`;
export const BlogImageHolder = styled.div`
    margin-bottom:20px;
`;
export const BlogTextLayout = styled.div`
    padding:0px 30px 30px;
`;
export const BlogDate = styled(Commonpara)`
    margin-bottom:10px;
    color:#2560d4;
`;
export const BlogHeading = styled(Commonh3)`

`;
export const BlogText = styled(Commonpara)`

`;
export const AuthorLayout = styled.div`
    display:flex;
    align-items:flex-start;
    background:#222;
    padding:30px;

    @media ${device.mobileM} {
        flex-direction:column
    }
`;
export const AuthorImageHolder = styled.div`
    width:80px;
    flex-shrink:0;
`;
export const AuthorTextLayout = styled.div`
    margin-left:20px;

    @media ${device.mobileM} {
        margin-left:0px;
        margin-top:20px;
    }
`;
export const AuthorName = styled(Commonh4)`
    text-align:left;
    color:#fff;
    font-size:24px;
    margin-bottom:10px;
`;
export const AuthorDesc = styled(Commonpara)`
    text-align:left;
    color:#fff;
`;
export const ButtonLayout = styled.div`
    display:flex;
    justify-content:space-between;
    margin-top:30px;
`;
export const PreviousPost = styled(Commonbtn)`
    @media ${device.mobileL} {
        padding:12px 10px;
    }
`;
export const NextPost = styled(Commonbtn)`
    @media ${device.mobileL} {
        padding:12px 10px;
    }
`;
export const Divider = styled.hr`
    margin:40px 0px;
`;
export const RelatedPostLayout = styled.div`

`;
export const RelatedPostHeading = styled(Commonh4)`
    text-align:left;
    margin-bottom:0px;
`;
export const RelatedPostImgHolder = styled.div`
    margin-bottom:20px;
    margin-top:30px;
`;
export const RelatedPostTitle = styled(AnchorLink)`
    text-align:left;
    font-size:20px;
    line-height:25px;
`;

