import React from 'react';
import {BlogDetailsWrapper,BlogContent,BlogImageHolder,BlogTextLayout,
    BlogDate,BlogHeading,BlogText,AuthorLayout,AuthorImageHolder,AuthorTextLayout,
    AuthorName,AuthorDesc,ButtonLayout,PreviousPost,NextPost,Divider,RelatedPostLayout,
    RelatedPostHeading,RelatedPostImgHolder,RelatedPostTitle
} from './blogdetails.style';
import {Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const BlogDetailsSection  = () => {

    const JSONData  = useStaticQuery(graphql`
    query {
        blogPageJson{
            BlogDetails{
                BlogImage{
                    childImageSharp{
                        fluid(quality: 100){
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                BlogDate
                BlogHeading
                BlogText1
                BlogText2
                BlogText3
                AuthorImage{
                    childImageSharp{
                        fluid(quality: 100){
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                AuthorName
                AuthorDesc
                PreviousPost
                NextPost
                RelatedPostHeading
                RelatedPostDetails{
                    RelatedPostImg{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    RelatedPostTitle
                }
            }
        }
    }
  `);

    return (
        <BlogDetailsWrapper>
            <BlogContent>
                <BlogImageHolder>
                    <GatsImg 
                        fluid={JSONData.blogPageJson.BlogDetails.BlogImage.childImageSharp.fluid} 
                        alt=""
                    />
                </BlogImageHolder>
                <BlogTextLayout>
                    <BlogDate>
                    {JSONData.blogPageJson.BlogDetails.BlogDate} 
                    </BlogDate>
                    <BlogHeading>
                    {JSONData.blogPageJson.BlogDetails.BlogHeading} 
                    </BlogHeading>
                    <BlogText>
                    {JSONData.blogPageJson.BlogDetails.BlogText1} 
                    </BlogText>
                    <BlogText>
                    {JSONData.blogPageJson.BlogDetails.BlogText2} 
                    </BlogText>
                    <BlogText>
                    {JSONData.blogPageJson.BlogDetails.BlogText3} 
                    </BlogText>
                </BlogTextLayout>
            </BlogContent>
            <AuthorLayout>
                <AuthorImageHolder>
                    <GatsImg 
                        fluid={JSONData.blogPageJson.BlogDetails.AuthorImage.childImageSharp.fluid} 
                        alt=""
                    />
                </AuthorImageHolder>
                <AuthorTextLayout>
                    <AuthorName>
                    {JSONData.blogPageJson.BlogDetails.AuthorName} 
                    </AuthorName>
                    <AuthorDesc>
                    {JSONData.blogPageJson.BlogDetails.AuthorDesc} 
                    </AuthorDesc>
                </AuthorTextLayout>
            </AuthorLayout>
            <ButtonLayout>
                <PreviousPost href="#">
                {JSONData.blogPageJson.BlogDetails.PreviousPost} 
                </PreviousPost>
                <NextPost href="#">
                {JSONData.blogPageJson.BlogDetails.NextPost} 
                </NextPost>
            </ButtonLayout>
            <Divider/>
            <RelatedPostLayout>
                <RelatedPostHeading>
                {JSONData.blogPageJson.BlogDetails.RelatedPostHeading} 
                </RelatedPostHeading>
                <Row>
                    <Col md={6}>
                        <RelatedPostImgHolder>
                        <GatsImg 
                            fluid={JSONData.blogPageJson.BlogDetails.RelatedPostDetails[0].RelatedPostImg.childImageSharp.fluid} 
                            alt=""
                        />
                        </RelatedPostImgHolder>
                        <RelatedPostTitle href="">
                        {JSONData.blogPageJson.BlogDetails.RelatedPostDetails[0].RelatedPostTitle} 
                        </RelatedPostTitle>
                    </Col>
                    <Col md={6}>
                    <RelatedPostImgHolder>
                        <GatsImg 
                            fluid={JSONData.blogPageJson.BlogDetails.RelatedPostDetails[1].RelatedPostImg.childImageSharp.fluid} 
                            alt=""
                        />
                        </RelatedPostImgHolder>
                        <RelatedPostTitle href="">
                        {JSONData.blogPageJson.BlogDetails.RelatedPostDetails[1].RelatedPostTitle} 
                        </RelatedPostTitle>
                    </Col>
                </Row>
            </RelatedPostLayout>
        </BlogDetailsWrapper>
       
    );
}
export default BlogDetailsSection;