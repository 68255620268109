import React from 'react';
import {BlogSidebarWrapper,RecentPostHeading,RecentPostLayout,
    PostImageHolder,PostHeading,Divider,CategoryLayout,Category
} from './blogsidebar.style';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const BlogSidebarPage  = () => {

    const JSONData  = useStaticQuery(graphql`
    query {
        blogPageJson{
            BlogSidebar{
                RecentPostHeading
                RecentPost{
                    PostImage{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    PostHeading
                }
                CategoryPostHeading
                    CategoriesList{
                        Category
                    }
            }
        }
    }
  `);

    return (
        <BlogSidebarWrapper>
            <RecentPostHeading>
                {JSONData.blogPageJson.BlogSidebar.RecentPostHeading}
            </RecentPostHeading>
            {
                JSONData.blogPageJson.BlogSidebar.RecentPost.map((item,idx) => {
                return <RecentPostLayout>
                    <PostImageHolder>
                        <GatsImg 
                            fluid={item.PostImage.childImageSharp.fluid} 
                            alt=""
                            imgStyle={{
                                width:"100%",
                                height:"auto"
                            }}
                        />
                    </PostImageHolder>
                    <PostHeading href="">
                        {item.PostHeading}
                    </PostHeading>
                </RecentPostLayout>
                })
            }
            <Divider />
            <RecentPostHeading>
            {JSONData.blogPageJson.BlogSidebar.CategoryPostHeading}
            </RecentPostHeading>
            <CategoryLayout>
            {
                JSONData.blogPageJson.BlogSidebar.CategoriesList.map((item,idx) => {
                return <Category href="#">
                        {item.Category}
                    </Category>
                })
            }
            </CategoryLayout>
        </BlogSidebarWrapper>
       
    );
}
export default BlogSidebarPage;