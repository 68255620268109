import styled from 'styled-components';
import {AnchorLink,Commonh4} from '../../../components/common.style';
import {device} from '../../../components/device';

export const BlogSidebarWrapper = styled.div`
    background:#fafafa;
    padding:30px;

    @media ${device.mobileL} {
        padding:15px;
    }
`;
export const RecentPostHeading = styled(Commonh4)`
    text-align:left;
    margin-bottom:30px;
`;
export const RecentPostLayout = styled.div`
    display:flex;
    margin-bottom:10px;
    align-items:center;
`;
export const PostImageHolder = styled.div`
    width:80px;
    flex-shrink:0;

    @media ${device.laptop} {
        width:80px;
    }
`;
export const PostHeading = styled(AnchorLink)`
    margin-left:10px;
    margin-bottom:0px;
`;

export const Divider = styled.hr`
    margin:30px 0px;
`;

export const CategoryLayout = styled.div`
    
`;

export const Category = styled.a`
    display:inline-block;
    background:#2560d422;
    margin-bottom:5px;
    color:#2560d4;
    line-height:14px;
    padding:8px;
    font-size:12px;
    margin-right:5px;
`;